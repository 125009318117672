import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navigation from "./navigation";

const Hero = ({
	title,
	titleSize,
	dubai,
	description,
	type,
	backgroundImage,
	button1,
	button2,
	button3,
	button1Text,
	button2Text,
	button3Text,
	button1Link,
	button2Link,
	button3Link,
	button1Var,
	button2Var,
	button3Var,
	mobileButton2,
	customTitle,
}) => {
	return (
		<div className="position-relative bg-primary">
			<StaticImage
				src="../images/HERO-RJM-SVG-2.svg"
				placeholder="blurred"
				quality={100}
				style={{ zIndex: 0 }}
				className="mb-4 position-absolute d-none d-lg-block  w-100 h-100"
			/>
			<StaticImage
				src="../images/HERO-RJM-SVG-2 copy.svg"
				placeholder="blurred"
				quality={100}
				style={{ zIndex: 0 }}
				className="mb-4 position-absolute  d-lg-none  w-100 h-100"
			/>
			<Navigation dubai={dubai} />
			<Container
				style={{ zIndex: 1 }}
				className="py-lg-9 position-relative py-4 py-md-5 "
			>
				<Row className=" pb-5 ">
					<Col lg={7} className="text-white">
						{customTitle !== true && (
							<h1
								dangerouslySetInnerHTML={{ __html: title }}
								className={`pt-3 mb-3 text-uppercase ssp-bold hero-title breaking-text ${
									titleSize || ""
								}`}
							/>
						)}
						{customTitle === true && (
							<div
								dangerouslySetInnerHTML={{ __html: title }}
								className={` ${titleSize || ""}`}
							/>
						)}
						<p
							className="mb-lg-0 "
							style={{ fontSize: "140%", color: " #d1d1d1" }}
						>
							{description}
						</p>
						{button1 !== null && (
							<Button
								className={`mt-5 w-100 w-md-auto fs-5 me-md-4 py-2 px-4 cta-btn ${button1}`}
								variant={button1Var}
								as={Link}
								to={button1Link}
							>
								{button1Text}
							</Button>
						)}
						{button2 !== null && (
							<Button
								className={`mt-4 mt-md-5 fs-5 w-100 ${
									mobileButton2 ? "" : "d-none"
								} d-md-inline-block w-md-auto cta-btn py-2 px-4 ${button2} border-white`}
								variant={button2Var}
								as={Link}
								to={button2Link}
							>
								{button2Text}
							</Button>
						)}
						{button3 !== null && (
							<Button
								className={`mt-4 mt-md-5 fs-5 w-100 cta-btn w-md-auto py-2 px-4 ${button3}`}
								variant={button3Var}
								target="_blank"
								rel="noreferrer"
								href={button3Link}
							>
								{button3Text}
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Hero;
